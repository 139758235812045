<template>
  <div class="contentScrollContact">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {},
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    handleAnimation: function () {},
  },
};
</script>